export default {
  slope: [
    {
      name: "Datar (0-8 %)",
      code: "datar",
    },
    {
      name: "Landai (8-15 %)",
      code: "landai",
    },
    {
      name: "Agak Curam (15-25 %)",
      code: "agak_curam",
    },
    {
      name: "Curam (25-45 %)",
      code: "curam",
    },
    {
      name: "Sangat Curam (>45 %)",
      code: "sangat_curam",
    },
  ],
  access_to_lahan: [
    {
      name: "Jalan Kaki",
      code: "Jalan Kaki",
    },
    {
      name: "Mobil",
      code: "Mobil",
    },
    {
      name: "Motor",
      code: "Motor",
    },
  ],
  lahan_distance: [
    {
      name: "< 500m",
      code: "< 500m",
    },
    {
      name: "500m - 2km",
      code: "500m - 2km",
    },
    {
      name: "> 2km",
      code: "> 2km",
    },
  ],
  water_availability_level: [
    {
      name: "Kurang",
      code: 1,
    },
    {
      name: "Melimpah",
      code: 2,
    },
    {
      name: "Tidak Ada",
      code: 3,
    },
  ],
  water_availability: [
    {
      name: "Sungai",
      code: "Sungai",
    },
    {
      name: "Waduk",
      code: "Waduk",
    },
    {
      name: "Danau",
      code: "Danau",
    },
    {
      name: "Mata Air",
      code: "Mata Air",
    },
    {
      name: "Sumur",
      code: "Sumur",
    },
    {
      name: "Irigasi",
      code: "Irigasi",
    },
  ],
  access_to_water_sources: [
    {
      name: "< 200m",
      code: "< 200m",
    },
    {
      name: "> 200m",
      code: "> 200m",
    },
    {
      name: "Tidak Ada",
      code: "Tidak Ada",
    },
  ],
  yes_no_option: [
    {
      label: "Tidak Ada",
      code: "0",
    },
    {
      label: "Ada",
      code: "1",
    },
  ],
  question_option: [
    {
      label: "Tidak",
      code: "0",
    },
    {
      label: "Ya",
      code: "1",
    },
  ],
  soil_type: [
    {
      label: 'Aluvial',
      code: 'Aluvial',
    },
    {
      label: 'Alvisol',
      code: 'Alvisol',
    },
    {
      label: 'Andosol',
      code: 'Andosol',
    },
    {
      label: 'Andosol Distrik',
      code: 'Andosol Distrik',
    },
    {
      label: 'Andosol Eutrik',
      code: 'Andosol Eutrik',
    },
    {
      label: 'Andosol Litik',
      code: 'Andosol Litik',
    },
    {
      label: 'Andosol Melanik',
      code: 'Andosol Melanik',
    },
    {
      label: 'Arenosol',
      code: 'Arenosol',
    },
    {
      label: 'Entisol',
      code: 'Entisol',
    },
    {
      label: 'Gleisol',
      code: 'Gleisol',
    },
    {
      label: 'Grumosol',
      code: 'Grumosol',
    },
    {
      label: 'Histosol',
      code: 'Histosol',
    },
    {
      label: 'Inseptisol',
      code: 'Inseptisol',
    },
    {
      label: 'Kambisol',
      code: 'Kambisol',
    },
    {
      label: 'Latosol',
      code: 'Latosol',
    },
    {
      label: 'Latosol Haplik',
      code: 'Latosol Haplik',
    },
    {
      label: 'Latosol Kromik',
      code: 'Latosol Kromik',
    },
    {
      label: 'Litosol',
      code: 'Litosol',
    },
    {
      label: 'Mediteran',
      code: 'Mediteran',
    },
    {
      label: 'Molisol',
      code: 'Molisol',
    },
    {
      label: 'Nitosol',
      code: 'Nitosol',
    },
    {
      label: 'Organosol',
      code: 'Organosol',
    },
    {
      label: 'Podsolik',
      code: 'Podsolik',
    },
    {
      label: 'Podsolik Haplik',
      code: 'Podsolik Haplik',
    },
  ],
  farming_system: [
    {
      name: "Pertanian Skala Kecil (Sayuran)",
      code: "Pertanian Skala Kecil (Sayuran)",
    },
    {
      name: "Kopi",
      code: "Kopi",
    },
    {
      name: "Penanaman Pohon dan Tanaman secara Tumpang Sari",
      code: "Penanaman Pohon dan Tanaman secara Tumpang Sari",
    },
    {
      name: "Sawah",
      code: "Sawah",
    },
    {
      name: "Sangat Tererosi/Terkikis, Tidak Cocok untuk Pertanian",
      code: "Sangat Tererosi/Terkikis, Tidak Cocok untuk Pertanian",
    },
    {
      name: "Lainnya",
      code: "Lainnya",
    },
  ],
  contact: [
    {
      name: "Email",
      code: "Email",
    },
    {
      name: "Telepon",
      code: "Telepon",
    },
    {
      name: "Whatsapp",
      code: "Whatsapp",
    }
  ],
  land_used_proof: [
    {
      name: "Pemilik lahan menyediakan bukti penggunaan lahan",
      code: "Pemilik lahan menyediakan bukti penggunaan lahan",
    },
    {
      name: "Pemilik lahan tidak menyediakan bukti penggunaan lahan",
      code: "Pemilik lahan tidak menyediakan bukti penggunaan lahan",
    }
  ],
  expected_proof: [
    {
      name: "Vegetasi tutupan yang sangat minimum",
      code: "Vegetasi tutupan yang sangat minimum",
    },
    {
      name: "Tidak ada tutupan pohon yang signifikan",
      code: "Tidak ada tutupan pohon yang signifikan",
    }
  ],
  risk: [
    {
      name: "Informasi dari pemilik lahan",
      code: "Informasi dari pemilik lahan",
    },
    {
      name: "Pengamatan selama kunjungan",
      code: "Pengamatan selama kunjungan",
    },
    {
      name: "Ada tanda-tanda kejadian",
      code: "Ada tanda-tanda kejadian",
    }
  ],
  type_owner: [
    {
      name: "Sertifikat",
      code: "Sertifikat",
    },
    {
      name: "Letter C",
      code: "Letter C",
    },
    {
      name: "Akta Jual Beli",
      code: "Akta Jual Beli",
    },
    {
      name: "Lahan Waris/Sewa/Kuasa Garap",
      code: "Lahan Waris/Sewa/Kuasa Garap",
    }
  ]
};
